import React from 'react';
import {Link} from 'react-router-dom';
import FooterLogo from '../../assets/images/home/FooterLogo.webp';
import { ArrowRightCircleFill, EnvelopeFill, Facebook, GeoAltFill, Instagram, TelephoneFill, Twitter, TwitterX } from 'react-bootstrap-icons';

const Footer = () => {
  return (
    <>
    <section className="container-fluid bg-footer py-5">
      <div className="row justify-content-evenly gy-4">
        <div className="col-8 col-lg-3 text-center">
          <div className=''>
          <img src={FooterLogo} alt="" className="mx-auto w-50 d-block d-md-none d-xl-block" />
          <img src={FooterLogo} alt="" className="mx-auto mt-3 w-25 d-none d-md-block d-xl-none" />
            {/* <div className='pt-3 pt-xl-4'>
            <a href="tel:+12567745524" className='text-decoration-none text-white'>
            256-774-5524
            </a>
            </div> */}
            
            <div className='text-center py-3 pt-xl-4'>
            <a href="tel:+12567745524" className='text-decoration-none py-2 fw-500 btn-green text-white border-0 rounded-3 fs-ss-14 px-4' target='_blank'>
              256-774-5524
            </a>
          </div>            
          </div>
        </div>
        <div className="col-11 col-lg-3">
          <div className='text-white mb-2 mb-xl-4 fw-500 fs-ss-26-links'>
            Quick Links
          </div>
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/about-us" className='text-decoration-none text-white'>
            About Us
            </Link> 
          </div>
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/services" className='text-decoration-none text-white'>
            Services Offered
            </Link> 
          </div>
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/contact-us" className='text-decoration-none text-white'>
            Contact Us
            </Link> 
          </div>
          {/* <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/blogs" className='text-decoration-none text-white'>
            Blogs
            </Link> 
          </div> */}
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/health-links" className='text-decoration-none text-white'>
              Health Links
            </Link> 
          </div>
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/privacy-policy" className='text-decoration-none text-white'>
            Privacy Policy
            </Link> 
          </div>
        </div>
        <div className="col-11 col-lg-3">
          <div className='text-white mb-2 mb-xl-4 fw-500 fs-ss-26-links'>
            {/* Get in Touch */}
            Contact Us
          </div>
          <div className='mb-2'>
            <TelephoneFill className='text-white me-2'/> 
            <a href="tel:+12567745524" target='_blank' className='text-decoration-none text-white'>
            256-774-5524
            </a>
          </div>
          <div className='mb-2'>
            <EnvelopeFill className='text-white me-2'/> 
            <a href="mailto:info@madisonprimarycare.com" target='_blank' className='text-decoration-none text-white'>
            info@madisonprimarycare.com
            </a>
          </div>
          <div className='mb-3'>
            <GeoAltFill className='text-white me-2'/> 
            <a href="https://maps.app.goo.gl/b6vHT9M9eFNTYm39A" target='_blank' className='text-decoration-none text-white'>
            400 Sun Temple Dr. <br className='d-none d-lg-block'/>
            <span className='ms-lg-4'>  Madison, AL 35758 </span> 
            </a>
          </div>
          <div className=''>
          <a className='text-decoration-none text-white' target='_blank' href="https://www.facebook.com/MadisonPrimaryCare.LLC/"> <Facebook className='text-ss-green me-2 px-1 fs-ss-35 border-social rounded-5'/> </a>
          <a className='text-decoration-none text-white' target='_blank' href="https://twitter.com/madison_primary"> <TwitterX className='text-ss-green me-2 px-1 fs-ss-35 border-social rounded-5'/> </a>
          <a className='text-decoration-none text-white' target='_blank' href="https://www.instagram.com/madisonprimarycare.bodla/"> <Instagram className='text-ss-green me-2 px-1 fs-ss-35 border-social rounded-5'/> </a>
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid py-2 bg-white fs-ss-14 text-black text-center">
      Copyright © 2024 Madison Primary Care. <br className='d-block d-md-none' /> Powered by <a className='text-ss-primary fw-500' href="https://simplesolutionz.org/" target='_blank'> Simple Solutionz </a>
    </section>
    </>
  );
}

export default Footer;
