import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/contactus/Banner.webp';
import {Link} from "react-router-dom";
import Telephone from '../assets/images/contactus/telephone.webp';
import LunchBreak from '../assets/images/contactus/lunch-break.webp';
import Fax from '../assets/images/contactus/fax.webp';
import Facebook from '../assets/images/contactus/Facebook.webp';
import Twitter from '../assets/images/contactus/Twitter.webp';
import Instagram from '../assets/images/contactus/Instagram.webp';
import { CupFill, EnvelopeFill, GeoAltFill, Printer, PrinterFill, TelephoneFill, Whatsapp } from 'react-bootstrap-icons';
import InquiryForm from '../components/InquiryForm';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
        Contact Us - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
           Contact Us
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Madison Primary Care
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className='container-lg pe-md-5 text-ss-secondary'>
                <div className='py-md-0'>
                <div className="">
                    <div className='row justify-content-center text-center gy-4'>
                        <div className='col-12 col-lg-3 mb-md-3 border-end border-2'>
                            <div className='my-2'>
                                <TelephoneFill className='text-ss-primary' size={40} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' >Phone</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="font-pri font-para hover-sec-head text-decoration-none text-ss-secondary" target="_blank" href="tel:+12567745524"> <span className='fw-500'> Phone: </span> 256-774-5524 </a></div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="font-pri font-para hover-sec-head text-decoration-none text-ss-secondary" target="_blank" href="fax:+12567745523"> <span className='fw-500'> Fax: </span> 256-774-5523 </a></div>
                            </div>
                            
                        </div>

                        <div className='col-12 col-lg-3 mb-md-3 border-end border-2'>
                            <div className='my-2'>
                                <CupFill className='text-ss-primary' size={40} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' > Lunch Break </div>
                                </div>
                            </div>
                            <div className='my-2 px-md-4'>
                                    <div className=''><span className="font-pri font-para hover-sec-head text-decoration-none text-ss-secondary">  12 PM - 1 PM DAILY </span></div>
                            </div>
                            
                        </div>

                        <div className='col-12 col-lg-3 mb-md-3'>
                            <div className='my-2'>
                              <GeoAltFill className='text-ss-primary' size={40} />
                              <div className='fs-ss-26 mt-3 mb-md-3'>
                                <div className='fw-600' > Address </div>
                              </div>
                            </div>
                            <div className='my-2 px-md-4'>
                              <div className=''><span className="">  
                              <a href="https://maps.app.goo.gl/b6vHT9M9eFNTYm39A" target='_blank' className='text-decoration-none text-dark'>
                              400 Sun Temple Dr. <br className='d-none d-lg-block'/>
                              Madison, AL 35758 
                              </a>
                              </span>
                              </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center align-items-center gy-4">
      <div className="col-11 col-lg-5 px-0 d-block d-lg-none">
        <InquiryForm/>
        </div>
        <div className="col-11 col-lg-5 bgcontactform px-lg-5 py-lg-4 py-5 px-4 rounded-start ">
        <div className=''>
            <div className='fs-ss-26 fw-700 lh-sm text-white '>
              Operating Hours
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-500 lh-para'>
            <table className="table">
              <tbody>
                <tr>
                  <td> Monday: </td>
                  <td className='text-end'> 7:00 AM - 5:00 PM </td>
                </tr>

                <tr>
                  <td> Tuesday: </td>
                  <td className='text-end'> 7:00 AM - 5:00 PM </td>
                </tr>

                <tr>
                  <td> Wednesday: </td>
                  <td className='text-end'> 7:00 AM - 5:00 PM </td>
                </tr>

                <tr>
                  <td> Thursday: </td>
                  <td className='text-end'> 7:00 AM - 5:00 PM </td>
                </tr>

                <tr>
                  <td> Friday: </td>
                  <td className='text-end'> 7:00 AM - 5:00 PM </td>
                </tr>

                <tr>
                  <td> Saturday: </td>
                  <td className='text-end'> Closed </td>
                </tr>

                <tr>
                  <td> Sunday: </td>
                  <td className='text-end'> Closed </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
        <div className="col-11 col-lg-5 px-0 d-none d-lg-block">
        <InquiryForm/>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>
    <section className='mt-lg-3'/>

    <section id='website-responsive' className='container-lg pe-md-5 text-ss-secondary'>
                <div className='py-md-0 py-3'>
                <div className="">
                    <div className='row justify-content-center text-center'>
                    <div className='fs-ss-30 fw-600 fw-600 lh-sm mb-md-5 mb-4'>
                    Holidays <span className='text-ss-primary'> Observed </span>
                      </div>
                        <div className='col-12 col-md-2 border-end border-2'>
                                <div className='fs-ss-18'>
                                    <div className='fw-600' > Labor Day </div>
                                </div>
                        </div>
                        <div className='col-12 col-md-2 border-end border-2'>
                                <div className='fs-ss-18'>
                                    <div className='fw-600' > Christmas Day </div>
                                </div>
                        </div>
                        <div className='col-12 col-md-2 border-end border-2'>
                                <div className='fs-ss-18'>
                                    <div className='fw-600' > Memorial Day </div>
                                </div>
                        </div>
                        <div className='col-12 col-md-2 border-end border-2'>
                                <div className='fs-ss-18'>
                                    <div className='fw-600' > Thanksgiving Day </div>
                                </div>
                        </div>
                        <div className='col-12 col-md-2 border-end border-2'>
                                <div className='fs-ss-18'>
                                    <div className='fw-600' > New Years Day </div>
                                </div>
                        </div>
                        <div className='col-12 col-md-2'>
                                <div className='fs-ss-18'>
                                    <div className='fw-600' > Independence Day </div>
                                </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className='pt-4 pt-md-5'/>

    <div className='border-0 z-back-page' >
      <iframe className='w-100 shadow' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13118.736211719222!2d-86.739948!3d34.7131481!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88626570bb05ac5b%3A0x6af02dae9462c733!2sMadison%20Primary%20Care!5e0!3m2!1sen!2s!4v1711517523745!5m2!1sen!2s" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

    <section className='pt-4 pt-md-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
              Connect <span className='text-ss-primary'> With Us </span>
              </div>
              <div className='pt-4'/>
              <div className="text-center">
              <a href="https://www.facebook.com/MadisonPrimaryCare.LLC/" className='' target='_blank'>
              <img className='w-social-media-icons' src={Facebook} alt="" />
              </a> 
              <span className='px-2 px-md-4' />
              <a href="https://twitter.com/madison_primary" className='' target='_blank'>
              <img className='w-social-media-icons' src={Twitter} alt="" />
              </a>  
              <span className='px-2 px-md-4' />
              <a href="https://www.instagram.com/madisonprimarycare.bodla/" className='' target='_blank'>
              <img className='w-social-media-icons' src={Instagram} alt="" />
              </a>      
              {/* <span className='px-4' />
              <a href="https://twitter.com/madison_primary" className='' target='_blank'>
              <img className='w-social-media-icons pe-5 pe-md-2 ' src={Instagram} alt="" />
              </a>                 */}
              </div>
            </div>
        </div>
      </div>
    </section>
          
    <section className='pt-4 pt-md-5'/>

    <Footer/>
    </>
  );
}

export default ContactUs;
