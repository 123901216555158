import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'

const InquiryForm = () =>{
  const [verified, setVarified] = useState(false)
  function onChange(value) {
    console.log("Captcha value:", value);
    setVarified(true);
  }
  const refform = useRef();
  const sendForm = (e) =>{
    e.preventDefault();
  emailjs.sendForm('gmail_service','madison',e.target,'3cIb1sgzlfNqwBYS8').then(res=>{
    console.log(res)
    alert("Your form has been submitted")
  }).catch(err=>{
    console.log(err)
  });
  e.target.reset()
};

    return (
    <>
    <section className="container-fluid px-4 px-lg-5 mt-lg-4 bg-grey-2 rounded-5 shadow-ss-1">
        <div className="row justify-content-center align-items-center py-5 ">
            <div className="col-12">
                <div className="">
                <div className='fs-ss-30 fw-600 lh-sm text-center text-ss-primary'>
                  DROP US A MESSAGE 
                </div>
                <div className="text-center col-12">
                <form ref={refform} onSubmit={sendForm} id="contactForm" method="POST" action="#" className="px-lg-4">
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="name">Name</label>
        <input type="text" className="form-control" id="name" name="name" placeholder="Name*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="email">Email</label>
        <input type="email" className="form-control" id="email" name="email" placeholder="Email*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="phone_number">Phone Number</label>
        <input type="number" className="form-control" id="phone_number" name="phone_number" placeholder="Phone Number*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="message">Message</label>
        <textarea className="col-pri form-control" id="message" name="message" rows="2" placeholder="Message*" required></textarea>
      </div>
      <div className="pt-4">
      <ReCAPTCHA
      sitekey="6LczmagpAAAAAMChSAHAJYbF_6n7yE0jaSH-ylfZ" 
      onChange={onChange}/>
      <button className='mt-4 text-center nav-link fw-500 shadow-ss-1 btn-black border-0 rounded-3 border-0 text-white fs-ss-16 px-3 py-1 bg-hover-green'>
            Submit
        </button>
      </div>
    </form>
    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    )
}

export default InquiryForm;